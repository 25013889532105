 <template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    autocomplete="off"
  >
  <v-container>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Control de temperatura
    </div>

    <!-- <v-row dense no-gutters class="mt-3">
      <v-col cols="6" sm="5" md="3" lg="3" xl="2">
        <CtrlCatFind class="mb-n3" ref="Ctrl" label="Tipo" :CtrlCatIdParent="1" v-model="tipo" :readonly="true" @change="ctrlCatChange" ></CtrlCatFind>
      </v-col>
    </v-row> -->

  <v-row dense>
    <v-col class="mb-n5" cols="6" sm="5" md="3" lg="3" xl="2">
      <DateFind class="mb-n3" label="Fecha" v-model="fecha"></DateFind>
    </v-col>

    <v-col cols="6" sm="5" md="3" lg="3" xl="2">
      <CtrlCatFind class="mb-n3" ref="Ctrl" label="Tipo" :CtrlCatIdParent="1" v-model="tipo" :readonly="true" :selectOnReadonly="true"></CtrlCatFind>
      <!-- @change="ctrlCatChange" -->
    </v-col>

    <v-col cols="12" sm="12" md="6" lg="8" xl="8">
      <PerFind class="mb-n3" label="Empleado" :perCatIdAnyLevel="101" v-model="empleado"></PerFind>
      <!-- <PerSel class="mb-n3" label="Empleado" :perCatIdAnyLevel="1" v-model="empleado"></PerSel> -->
    </v-col>        
  </v-row>

  <v-row dense>
    <v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="itemsMaq"
        :items-per-page="-1"
        disable-pagination
        hide-default-footer
        style="overflow: scroll"
        mobile-breakpoint="0"
        class="mb-5 elevation-1">

        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.MaqTtra="{ item }">
          <v-text-field dense reverse v-model="item.MaqTtra" type="number" class="pt-0 pb-1 mb-n7" solo flat @change="TemperaturaCambiada"></v-text-field>
        </template>
      </v-data-table>          
    </v-col>
  </v-row>

  <v-row dense>
    <v-textarea
      class="mb-n5"
      outlined
      name="obsCtrl"
      label="Observaciones"
      v-model="obs"
    ></v-textarea>
  </v-row>

    <v-row style="height:60px" no-gutters>
      <v-col>
      <div class="text-center">
          <v-btn
            color="normal"
            class="mt-4 mr-2"
            @click="grabar"
            style="width:100px"
            ref="aceptarBtn"
            >
            Aceptar
          </v-btn>

          <v-btn
            color="normal"
            class="mt-4"
            @click="cancelar"
            style="width:100px"
            ref="cancelarBtn"
            >
              Cancelar
          </v-btn>
      </div>
      </v-col>
    </v-row>
  </v-container>
  </v-form>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  import router from '../router'
  import CtrlCatFind from "../components/CtrlCatFind.vue";
  import PerFind from "../components/PerFind.vue";
  // import PerSel from "./PerSel.vue";
  import DateFind from "../components/DateFind.vue";

  export default {
    components: {
       CtrlCatFind,
       PerFind,
      //  PerSel,
       DateFind
     },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('Ctrls', ['entidadActual']),
    },
    data: () => ({
      valid: true,
      fecha: '',
      tipo: {},
      empleado: {},
      obs: '',
      itemsMaq: [],
        headers: [
          { text: 'ELEMENTO', value: 'MaqNom', class: 'col_maq' },
          { text: 'TEMPERATURA', value: 'MaqTtra', width: '140' },
          { text: 'VALOR NORMAL', value: 'MaqTtraRangoTxt', align: 'center', width: '220' },
        ],

      CtrlCat: {},
    }),
    watch: {
    },

    methods: {
      validar () {
        this.$refs.form.validate();
        var ValRet = this.valid;

        if (this.valid) {
            if (this.tipo == null ) {
              alert('Debe elegir un tipo de control de temperatura');
              ValRet = false;
            }
            else {
              if (this.empleado == null || this.empleado.length == 0) {
                alert('Debe indicar el empleado encargado de la toma de temperaturas');
                ValRet = false;
              }
            }

          // if (this.paraUsuario.length == 0 && this.paraGrupo.length == 0) {
          //   alert("Debe haber al menos un destinatario del mensaje")
          //   ValRet = false;
          // }
          // else {
          //   if (this.textoMensaje.length == 0) {
          //     alert("El texto no puede estar vacío");
          //     ValRet = false;
          //   }
          // }
        }
        return ValRet;
      },   
      TemperaturaCambiada() {
        // this.grabar();
      },  
      grabar () {
        if (this.validar())
        {
          this.entidadActual.CtrlTmp = this.fecha;
          this.entidadActual.CtrlCatId = this.tipo.CtrlCatId
          this.entidadActual.CtrlCat = this.tipo;
          this.entidadActual.PerId = this.empleado.PerId;
          this.entidadActual.Per = this.empleado;
          this.entidadActual.CtrlObs = this.obs;

          // // Inicio de conversión para que el backend no grabe los tablas relacionadas
          // var MsjPer = [];
          // this.paraUsuario.forEach(element => {
          //   var msjPerObj = {MsjId: this.entidadActual.MsjId, PerId: element.PerId, MsjLeido: element.MsjLeido}
          //   MsjPer.push(msjPerObj);
          // });

          // this.entidadActual.MsjPer = MsjPer;

          // var MsjPerCat = [];
          // this.paraGrupo.forEach(element => {
          //   var msjPerCatObj = {MsjPerCatId: element.MsjPerCatId, MsjId: this.entidadActual.MsjId, PerCatId: element.PerCatId};

          //   MsjPerCat.push(msjPerCatObj);
          // });

          // var MsjFic = [];

          // this.Adjuntos.forEach(element => {
          //   var msjFicObj = {MsjFicId: element.MsjFicId, MsjId: this.entidadActual.MsjId, FicNom: element.FicNom, FicNomReal: element.FicNomReal}
          //   MsjFic.push(msjFicObj);
          // })

          // this.entidadActual.MsjFic = MsjFic;

          // if (this.msjCat != null) {
          //   var MsjCatMsj = [];
          //   var msjCatMsjObj = { MsjCatMsjId: this.msjCat.MsjCatMsjId, MsjId: this.entidadActual.MsjId, MsjCatId: this.msjCat.MsjCatId }
          //   MsjCatMsj.push(msjCatMsjObj);
          // }

          // this.entidadActual.MsjCatMsj = MsjCatMsj;

          // if (this.lectorPerCat != null) {
          //   var MsjLecCnd = [];
          //   var msjLecCndObj = { MsjLecCndId: this.lectorPerCat.MsjLecCndId, MsjId: this.entidadActual.MsjId, PerCatId: this.lectorPerCat.PerCatId }
          //   MsjLecCnd.push(msjLecCndObj);
          // }

          // this.entidadActual.MsjLecCnd = MsjLecCnd;

          // Final de conversión para que el backend no grabe los tablas relacionadas

          // this.entidadActual.MsjPerCat = MsjPerCat;

          // delete this.entidadActual.MsjFecFormatted;
          // delete this.entidadActual.MsjPara;
          // delete this.entidadActual.MsjLeido;
          // delete this.entidadActual.FecLec;

          delete this.entidadActual.Dir;
          delete this.entidadActual.Per;
          delete this.entidadActual.CtrlCat;
          delete this.entidadActual.CtrlFechsStr;

          // var CtrlMaqNue = []
          // this.entidadActual.CtrlMaq.forEach(element => {
          //   var obj = {CtrlMaqId: element.CtrlMaqId, CtrlId: element.CtrlId, MaqId: element.MaqId, MaqTtra: Number(element.MaqTtra)}
          //   CtrlMaqNue.push(obj);
          // });
          // this.entidadActual.CtrlMaq = CtrlMaqNue;


          var controllerParameters = {
              Action: 'SAVE_DATA',
              Ctrl: this.entidadActual
          };

          var AuthToken = localStorage.getItem('token');
          // axios({ method: "PUT", "url": this.urlRaiz + "/api/mensajes", "data": entidadJson, "headers": { "content-type": "application/json" } })
          axios({ method: "POST", "url": this.urlRaiz + "/api/ctrl", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
              .then(result => {
                if (result.status >= 200 && result.status <= 299) {
                  alert('Datos grabados con éxito');
                  router.push('/control-temperatura2');
                }
              })
              .catch(error => {
                  alert(error);
              })
        }
      },
    cancelar() {
      router.push('/control-temperatura2');
    },
    perCatChange() {
    },
  },
  mounted() {
    this.fecha = this.entidadActual.CtrlTmp.substr(0, 10);
    if (this.fecha == null || this.fecha == '') {
      this.fecha
    }
    this.tipo = this.entidadActual.CtrlCat;
    this.empleado = this.entidadActual.Per;
    this.obs = this.entidadActual.CtrlObs;
    this.itemsMaq = this.entidadActual.CtrlMaq;

    // this.paraUsuario = this.entidadActual.MsjPer;
    // if (this.paraUsuario == null)    {
    //   this.paraUsuario = [];
    // }
    // this.tipoGrupo = 102;
    // this.paraGrupo = this.entidadActual.MsjPerCat;
    // this.msjCat = this.entidadActual.MsjCatMsj[0];
    // this.lectorPerCat = this.entidadActual.MsjLecCnd[0];
    //    var objCat = { MsjCatId: this.entidadActual.MsjCatMsj[0].MsjCatId, MsjCatNom: this.entidadActual.MsjCatMsj[0].MsjCatNom } ;
    //    this.msjCat = objCat;
    //    this.msjCat = this.entidadActual.MsjCatMsj;

    // if (this.paraGrupo == null) {
    //   this.paraGrupo = [];
    // }

    // this.items = this.entidadActual.MsjPer;

    // this.asunto = this.entidadActual.MsjNom;
    // this.textoMensaje = this.entidadActual.MsjTxt;

    // if (this.entidadActual.MsjFic != undefined && this.entidadActual.MsjFic != null) {
    //   this.Adjuntos = this.entidadActual.MsjFic;
    // }
  }
}
</script>
