<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="CtrlCatId"
        item-text="CtrlCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="CtrlCatFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'PerId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: [
      'value', 'CtrlCatIdParent', 'CtrlCatIdAnyLevel', 'tipoFiltro', 'label', 
      'multiple', 'readonly', 'defValue', 'smallChips', 'deletableChips', 
      'perIdRegistrado', 'selectOnReadonly'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      CtrlCatIdParent() {
        this.$refs.CtrlCatFindCtrl.cachedItems = [];
        this.loadItems('');
      },
      value() {
        if (this.value != null) {
          this.loadItems('');
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            CampoBusqueda: 'CtrlCatNom',
            ValorBuscar: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            CtrlCatIdParent: this.CtrlCatIdParent, 
            CtrlCatIdAnyLevel: this.CtrlCatIdAnyLevel,
            TipoFiltro: this.tipoFiltro,
            PerIdRegistrado: this.perIdRegistrado
        };

      var defVal = this.defValue; // si no se asigna a variable y se asigna con this no lo coge 
      var curVal = this.value;

      var AuthToken = localStorage.getItem('token');  
       axios({ method: "POST", "url": this.urlRaiz + "/api/CtrlCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
       .then(result => {
            this.itemsList = [];

            result.data.EntsList.forEach(element => {
              var itemObject = {CtrlCatId: element.CtrlCatId, CtrlCatNom: element.CtrlCatNom}
              this.itemsList.push(itemObject);
            });

            // if (curVal.length == null) {  
            if (!Array.isArray(curVal)) {  // es un objeto
              if (curVal != null && curVal.CtrlCatId != null) {
                if (!this.itemsList.some(itm=>itm.CtrlCatId === curVal.CtrlCatId)) {
                  this.itemsList.push(curVal);
                }
              }
              else {
                if (defVal != null && this.itemsList != null && this.itemsList.length > 0) {
                  var itmDefault = this.itemsList.find(itm=>itm.CtrlCatId === parseInt(defVal))
                  if (!this.itemsList.some(itm=>itm.CtrlCatId === parseInt(defVal))) {
                    this.itemsList.push(itmDefault);
                  }
                  this.value = itmDefault;
                  this.$emit('input', this.value);
                }
              }            
            }
            else {
              curVal.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.CtrlCatId === element.CtrlCatId)) {
                    this.itemsList.push(element);
                  }
              });
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.CtrlCatFindCtrl.isMenuActive) {
            this.$refs.CtrlCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.CtrlCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.CtrlCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems('');
      // if (this.CtrlCatIdParent != null) {
      // }
    },
  }
</script>